/**
 * Created by Outback on 7/23/2016.
 */
var Parlay = Parlay || {};

function startUp(params, isGuest) {
    "use strict";

    if (_.isUndefined(params)) { params = {}; }
    if (!_.isUndefined(isGuest)) { params.isGuest = isGuest; }

    isGuest = isGuest == true ? isGuest : false;

    console.log('isGuest: ' + isGuest);

    Parlay.global = Parlay.global ||
        {
            lang: !_.isUndefined(params.game_lang) ? params.game_lang : 'en',
            style: !_.isUndefined(params.game_style) ? params.game_style : (!_.isUndefined(game_style) ? game_style : undefined),
            isGuest: !_.isUndefined(params.isGuest) ? params.isGuest : false,
            isApp: !_.isUndefined(window.parlayApi.buildInfo) ? true : false,
            guestID: 1,

            aServ: !_.isUndefined(params.pgs_root) ? params.pgs_root : (!_.isUndefined(pgs_root) ? pgs_root : undefined),
            nServ: !_.isUndefined(params.node_root) ? params.node_root : (!_.isUndefined(node_root) ? node_root : undefined),

            localAssets: false,

            title: 'socialBingo',
            dpi: 2,
            // dpi: parseInt(window['devicePixelRatio']),

            viewType: undefined,
            ViewStandard: "default",
            ViewCompact: "compact",

            branding: '',
            config: undefined,

            resolutionWidth: undefined,
            resolutionHeight: undefined,
            wPerc: undefined,
            hPerc: undefined,
            width: undefined,
            height: undefined,
            wOffset: undefined,
            hOffset: undefined
        };

    Parlay.global.viewType = Parlay.global.ViewStandard;

    if (Parlay.global.localAssets == true) {
        Parlay.global.ogAServ = Parlay.global.aServ;
        Parlay.global.aServ = 'gameAssets/';
    }

    if (window.innerWidth < window.innerHeight) {
        if (window.innerHeight < 750) {
            Parlay.global.viewType = Parlay.global.ViewCompact;
        }
    } else {
        if (window.innerWidth < 750) {
            Parlay.global.viewType = Parlay.global.ViewCompact;
        }
    }

    var presetW, presetH;
    switch(Parlay.global.viewType) {
        case Parlay.global.ViewCompact:
            presetW = 568;
            presetH = 320;
            break;
        default:
            presetW = 1024;
            presetH = 768;
            break;
    }

    var hPerc, wPerc;
    wPerc = presetW / window.innerWidth;
    hPerc = presetH / window.innerHeight;

    if (wPerc < hPerc) {
        // wPerc = wPerc;
        hPerc = wPerc;
    } else {
        wPerc = hPerc;
        // hPerc = hPerc;
    }

    Parlay.global.resolutionWidth = presetW;
    Parlay.global.resolutionHeight = presetH;
    Parlay.global.wPerc = wPerc;
    Parlay.global.hPerc = hPerc;
    Parlay.global.width = window.innerWidth * wPerc;
    Parlay.global.height = window.innerHeight * hPerc;
    Parlay.global.wOffset = (((window.innerWidth * wPerc) - presetW) / 2);
    Parlay.global.hOffset = (((window.innerHeight * hPerc) - presetH) / 2);

    Parlay.global.branding = !_.isUndefined(Parlay.global.style) ? Parlay.global.style : 'default';

    Parlay.game = new Phaser.Game({
        forceSetTimeOut: true,
        transparent: true,
        // parent: '',
        width: Parlay.global.width * Parlay.global.dpi,
        height: Parlay.global.height * Parlay.global.dpi,
        renderer: Phaser.CANVAS,
        // renderer: Phaser.AUTO,
        antialias: true
    });


    Parlay.resizeCnt = 0;

    //==============================

    Parlay.loadLogic = function(pack, scope) {
        pack.forEach(function(args) {
            // if (!_.isUndefined(args.fileType)) { args.fileType += cB; }
            if (!_.isUndefined(args.method)) {
                var loader = scope.load[args.method],
                    dataObj = [args.label];

                switch(args.method) {
                    case 'script':
                    case 'json':
                        dataObj.push(args.directory + args.fileName + '.' + args.fileType + (args.forceNew == true ? ('?v=' + Date.now()) : ''));
                        break;
                    case 'image':
                    case 'physics':
                    case 'spine':
                        dataObj.push(args.directory + args.fileName + '.' + args.fileType);
                        break;
                    case 'atlasJSONHash':
                    case 'bitmapFont':
                        dataObj.push(args.directory + args.fileName + '.' + args.fileType);
                        dataObj.push(args.directory + args.secondaryFileName + '.' + args.secondaryFileType);
                        break;
                    case 'audiosprite':
                        if (_.isUndefined(this.hasAudioSprite)) { this.hasAudioSprite = []; }

                        this.hasAudioSprite.push(args.label);

                        var i, iTot, urls;
                        iTot = args['formats'].length;
                        urls = [];
                        for (i = 0; i < iTot; i++) {
                            urls.push(args.directory + args.fileName + '.' + args['formats'][i]);
                        }
                        dataObj.push(urls);
                        dataObj.push(args.directory + args.secondaryFileName + '.' + args.secondaryFileType);
                        dataObj.push(undefined);
                        dataObj.push(true);
                        break;
                }

                loader && loader.apply(scope.load, dataObj);
            }
        }, scope);
    };
    Parlay.brandCheck = function(atlasLabel, addViewType) {
        if (_.isUndefined(addViewType)) { addViewType = true; }

        var brandData = 'branding/';
        if (!_.isUndefined(Parlay.global.branding) &&
            Parlay.global.branding != '') {

            if (_.isUndefined(Parlay.branding)) {
                Parlay.branding = Parlay.game.cache.getJSON('branding');
            }

            if (!_.isUndefined(Parlay.branding) &&
                Parlay.branding != null &&
                !_.isUndefined(Parlay.branding[atlasLabel])) {
                brandData += Parlay.global.branding;
            } else {
                brandData += 'default';
            }

            brandData += '/';
        }

        if (addViewType == true) {
            brandData += Parlay.global.viewType + '/';
        }

        return (brandData);
    };

    //==============================

    Parlay.Boot = Parlay.Boot || function(){};
    Parlay.Boot.prototype = {
        preload: function () {

            // Show something other then black;
            Parlay.game.canvas.style.backgroundImage = "linear-gradient(66deg, #61016c 0%, #42075e 48%, #0c0c44 100%)"

            this.load.crossOrigin = 'Anonymous';

            // this.stage.disableVisibilityChange = true;

            this.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL;

            // Parlay.game.scale.onSizeChange.add(ParlayAPI.resizePlugin.resizeMe);

            this.scale.pageAlignHorizontally = true;
            this.scale.pageAlignVertically = true;

            /*
             {
             method:'atlasJSONHash', label:'default',
             fileName:'default', fileType:'png',
             secondaryFileName:'default', secondaryFileType:'json',
             directory:stockURL+'default'
             }
             {
             method:'script', label:'default',
             fileName:'default', fileType:'js',
             directory:stockURL+'default'
             }
             {
             method:'json', label:'default',
             fileName:Parlay.global.lang, fileType:'json',
             directory:stockURL+'default'
             }
             {
             method:'audio', label:'default',
             fileName:'default', formats:'default', autoDecode:true,
             secondaryFileName:'default', secondaryFileType:'default',
             directory:stockURL+'default'
             }
             {
             method:'bitmapFont', label:'default',
             fileName:'default', fileType:'png',
             secondaryFileName:'default', secondaryFileType:'xml',
             directory:stockURL+'default'
             }
             */

            var pack = [];
            var stockURL = Parlay.global.aServ;

            pack.push({
                method:'script', label:'state_Loader',
                fileName:Parlay.global.title+'.loader.min', fileType:'js',
                directory:stockURL+'bin/',
                forceNew: true
            });

            // pack.push({
            //     method:'script', label:'particlestorm',
            //     fileName:'particle-storm.min', fileType:'js',
            //     directory:stockURL+'/libs/'
            // });

            pack.push({
                method:'script', label:'ninePatch',
                fileName:'parlay-nine-patch-phaser-plugin', fileType:'js',
                directory:stockURL+'libs/'
            });

            pack.push({
                method:'script', label:'phaserSpine',
                fileName:'phaser-spine.min', fileType:'js',
                directory:stockURL+'libs/'
            });

            if (!_.isUndefined(Parlay.global.branding) &&
                Parlay.global.branding != '') {

                var dataObj;

                dataObj = {
                    method: 'json', label: 'branding',
                    fileName: Parlay.global.branding, fileType: 'json',
                    directory: stockURL + 'img/branding/' + Parlay.global.branding + '/'
                };

                if (Parlay.global.localAssets == true) {
                    dataObj.directory = stockURL;
                }

                pack.push(dataObj);
            }

            if (_.isUndefined(Parlay.config)) {
                pack.push({
                    method: 'json', label: 'config',
                    fileName: 'config', fileType: 'json',
                    directory: stockURL + 'configs/'
                });
            }

            Parlay.loadLogic(pack, this);
        },
        create: function () {
            var brandData;

            if (_.isUndefined(Parlay.branding)) {
                Parlay.branding = Parlay.game.cache.getJSON('branding');
            }

            if (_.isUndefined(Parlay.config)) {
                Parlay.config = Parlay.game.cache.getJSON('config');
            }

            brandData = Parlay.branding;

            if (_.isUndefined(brandData) || brandData == null) {
                if (Parlay.global.branding != 'default') {
                    Parlay.global.branding = 'default';

                    Parlay.game.state.add('Boot', Parlay.Boot);
                    Parlay.game.state.start('Boot');
                }
            } else {
                // $('canvas').css('background-image', 'url("http://localhost/web/html5/assets_socialBingo/img/branding/warriorBingo/default/SBBackground2x/WBbackground1.jpg")');
                // $('canvas').css('background-size', 'cover');

                Parlay.game.canvas.style.backgroundImage = 'url("' + Parlay.global.aServ + 'img/' + Parlay.brandCheck('SBBackground') + 'SBBackground2x/SBBackground.jpg")';
                Parlay.game.canvas.style.backgroundSize = 'cover';

                // <-- This is the real kick off.
                Parlay.game.state.add('Preloader', Parlay.PreloaderState);
                this.state.start('Preloader', true, false, {kickOff:true});
            }
        },
        resize: function() {

        }
    };

    Parlay.game.state.add('Boot', Parlay.Boot);
    Parlay.game.state.start('Boot');
}